import http from '~/utils/http'


export class ProducerApi {
  // @POST('/jav/producer/query')
  // Future<List<Producer>> query();
  static query(opt?: HttpOptions) {
    return http.post<Array<Producer>>({ ...opt, url: '/jav/producer/query' })
  }

  // @POST('/jav/producer/videoQuery')
  // Future<FilterVideoPageResponse> videoQuery(@Body() ProducerFilterParam param);
  static videoQuery(params: ProducerFilterParam, opt?: HttpOptions) {
    return http.post<FilterVideoPageResponse>({ ...opt, url: '/jav/producer/videoQuery', body: params })
  }

  // @POST('/jav/producer/queryById')
  // Future<Producer> queryById(@Body() IdParam param);
  static queryById(params: IdParam, opt?: HttpOptions) {
    return http.post<Producer>({ ...opt, url: '/jav/producer/queryById', body: params })
  }
}
